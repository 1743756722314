<template>
  <div v-if="$ability.can('read', this.$route.meta.resource)">
    <p class="mb-2">
      กราฟิก
    </p>
    <b-row>
      <b-col
        md="6"
        lg="2"
      >
        <p>{{ $t('Featured graphic') }} *</p>
      </b-col>
      <b-col
        md="6"
        lg="10"
      >
        <b-card
          :title="$t('Featured graphic')"
          class="text-center"
          @dragover="dragover($event, 'featured_graphic')"
          @dragleave="dragleave('featured_graphic')"
          @drop="drop($event, 'featured_graphic')"
        >
          <div class="dropzone-container">
            <b-form-file
              id="fileFeatured_graphic"
              ref="featured_graphic"
              accept=".jpeg, .png"
              :hidden="true"
              name="featured_graphic"
              plain
              @change="onChange($event, 'featured_graphic')"
            />
            <label @click="$refs.featured_graphic.$el.click()">
              <div v-if="featured_graphicIsDragging">
                {{ $t('Release to drop files here') }}.
              </div>
              <div v-else>
                {{ $t('Drop files here or') }} <u>{{ $t('click here') }}</u>
                {{ $t('to upload') }}.
              </div>
            </label>
          </div>
          <div
            v-if="featured_graphic.length"
            class="preview-container"
          >
            <b-row>
              <b-col
                v-for="(item, index) in featured_graphic"
                :key="index"
                cols="12"
              >
                <div>
                  <b-img
                    fluid-grow
                    :src="item"
                    rounded
                    class="mt-1 preview-img"
                    :alt="`featured_graphic${index}`"
                  />
                  <b-button
                    variant="danger"
                    class="btn-remove"
                    @click="removeImage(index, 'featured_graphic')"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="mt-2 text-left">
            {{ featured_graphic.length }}/1
          </div>
        </b-card>
        <div class="mb-2">
          กราฟิกเด่นจะต้องอยู่ในรูปแบบ PNG หรือ JPEG มีขนาดสูงสุด 15 MB
          ความละเอียด 1,024 x 500 พิกเซล
        </div>
      </b-col>
    </b-row>
    <hr>
    <p class="mb-2">
      โทรศัพท์
    </p>
    <b-row>
      <b-col
        md="6"
        lg="2"
      >
        <p>{{ $t('Phone screenshot') }} *</p>
      </b-col>
      <b-col
        md="6"
        lg="10"
      >
        <b-card
          :title="$t('Phone screenshot')"
          class="text-center"
          @dragover="dragover($event, 'phone_screenshot')"
          @dragleave="dragleave('phone_screenshot')"
          @drop="drop($event, 'phone_screenshot')"
        >
          <div>
            <b-form-file
              id="filePhone_screenshot"
              ref="phone_screenshot"
              accept=".jpeg, .png"
              :hidden="true"
              name="phone_screenshot"
              plain
              @change="onChange($event, 'phone_screenshot')"
            />
            <label @click="$refs.phone_screenshot.$el.click()">
              <div v-if="phone_screenshotIsDragging">
                {{ $t('Release to drop files here') }}.
              </div>
              <div v-else>
                {{ $t('Drop files here or') }} <u>{{ $t('click here') }}</u>
                {{ $t('to upload') }}.
              </div>
            </label>
          </div>

          <div
            v-if="phone_screenshot.length"
            class="preview-container"
          >
            <draggable
              v-model="phone_screenshot"
              group="myGroup_phone_screenshot"
              tag="div"
              class="row"
            >
              <b-col
                v-for="(item, index) in phone_screenshot"
                :key="index"
                cols="2"
                :class="`dropzone${index}`"
              >
                <div :id="`phone_screenshot${index}`">
                  <b-img
                    fluid-grow
                    :src="item"
                    rounded
                    class="mt-1 preview-imgs"
                    :alt="`phone_screenshot_image${index}`"
                  />
                  <b-button
                    variant="danger"
                    class="btn-remove"
                    @click="removeImage(index, 'phone_screenshot')"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </div>
              </b-col>
            </draggable>
            <!-- <b-row>
              <b-col
                v-for="(item, index) in phone_screenshot"
                :key="index"
                cols="2"
                :class="`dropzone${index}`"
              >
                <div
                  :id="`phone_screenshot${index}`"
                >
                  <b-img
                    fluid-grow
                    :src="item"
                    rounded
                    class="mt-1 preview-imgs"
                    :alt="`phone_screenshot_image${index}`"
                  />
                  <b-button
                    variant="danger"
                    class="btn-remove"
                    @click="removeImage(index,'phone_screenshot')"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </div>

              </b-col>
            </b-row> -->
          </div>
          <div class="mt-2 text-left">
            {{ phone_screenshot.length }}/8
          </div>
        </b-card>
        <div>
          อัปโหลดภาพหน้าจอโทรศัพท์ 2-8 ภาพ ภาพหน้าจอต้องอยู่ในรูปแบบ PNG หรือ
          JPEG แต่ละภาพมีขนาดไม่เกิน 8 MB มีสัดส่วนภาพ 16:9 หรือ 9:16
          โดยที่แต่ละด้านมีความละเอียดอยู่ระหว่าง 320 ถึง 3,840 พิกเซล
        </div>
      </b-col>
    </b-row>
    <hr>
    <p class="mb-2">
      แท็บเล็ต
    </p>
    <b-row>
      <b-col
        md="6"
        lg="2"
      >
        <p>{{ $t('7 inch tablet screenshot') }} *</p>
      </b-col>
      <b-col
        md="6"
        lg="10"
      >
        <b-card
          :title="$t('7 inch tablet screenshot')"
          class="text-center"
          @dragover="dragover($event, 'tablet7')"
          @dragleave="dragleave('tablet7')"
          @drop="drop($event, 'tablet7')"
        >
          <div>
            <b-form-file
              id="fileTablet7"
              ref="tablet7"
              accept=".jpeg, .png"
              :hidden="true"
              name="tablet7"
              plain
              @change="onChange($event, 'tablet7')"
            />
            <label @click="$refs.tablet7.$el.click()">
              <div v-if="tablet7IsDragging">
                {{ $t('Release to drop files here') }}.
              </div>
              <div v-else>
                {{ $t('Drop files here or') }} <u>{{ $t('click here') }}</u>
                {{ $t('to upload') }}.
              </div>
            </label>
          </div>
          <div
            v-if="tablet7.length"
            class="preview-container"
          >
            <draggable
              v-model="tablet7"
              group="myGroup_tablet7"
              tag="div"
              class="row"
            >
              <b-col
                v-for="(item, index) in tablet7"
                :key="index"
                cols="2"
              >
                <div>
                  <b-img
                    fluid-grow
                    :src="item"
                    rounded
                    class="mt-1 preview-imgs"
                    :alt="`tablet7${index}`"
                  />
                  <b-button
                    variant="danger"
                    class="btn-remove"
                    @click="removeImage(index, 'tablet7')"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </div>
              </b-col>
            </draggable>
            <!-- <b-row>
              <b-col
                v-for="(item, index) in tablet7"
                :key="index"
                cols="2"
              >
                <div>
                  <b-img
                    fluid-grow
                    :src="item"
                    rounded
                    class="mt-1 preview-imgs"
                    :alt="`tablet7${index}`"
                  />
                  <b-button
                    variant="danger"
                    class="btn-remove"
                    @click="removeImage(index, 'tablet7')"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </div>
              </b-col>
            </b-row> -->
          </div>
          <div class="mt-2 text-left">
            {{ tablet7.length }}/8
          </div>
        </b-card>
        <div>
          อัปโหลดภาพหน้าจอแท็บเล็ตขนาด 7 นิ้วได้สูงสุด 8 ภาพ
          ภาพหน้าจอต้องอยู่ในรูปแบบ PNG หรือ JPEG แต่ละภาพมีขนาดไม่เกิน 8 MB
          มีสัดส่วนภาพ 16:9 หรือ 9:16 โดยที่แต่ละด้านมีความละเอียดอยู่ระหว่าง
          320 ถึง 3,840 พิกเซล
        </div>
      </b-col>
      <b-col
        md="6"
        lg="2"
      >
        <p>{{ $t('10 inch tablet screenshot') }} *</p>
      </b-col>
      <b-col
        md="6"
        lg="10"
      >
        <b-card
          :title="$t('10 inch tablet screenshot')"
          class="text-center"
          @dragover="dragover($event, 'tablet10')"
          @dragleave="dragleave('tablet10')"
          @drop="drop($event, 'tablet10')"
        >
          <div>
            <b-form-file
              id="fileTablet10"
              ref="tablet10"
              accept=".jpeg, .png"
              :hidden="true"
              name="tablet10"
              plain
              @change="onChange($event, 'tablet10')"
            />
            <label @click="$refs.tablet10.$el.click()">
              <div v-if="tablet10IsDragging">
                {{ $t('Release to drop files here') }}.
              </div>
              <div v-else>
                {{ $t('Drop files here or') }} <u>{{ $t('click here') }}</u>
                {{ $t('to upload') }}.
              </div>
            </label>
          </div>
          <div
            v-if="tablet10.length"
            class="preview-container"
          >
          <draggable
              v-model="tablet10"
              group="myGroup_tablet10"
              tag="div"
              class="row"
            >
              <b-col
              v-for="(item, index) in tablet10"
                :key="index"
                cols="2"
              >
              <div>
                  <b-img
                    fluid-grow
                    :src="item"
                    rounded
                    class="mt-1 preview-imgs"
                    :alt="`tablet10${index}`"
                  />
                  <b-button
                    variant="danger"
                    class="btn-remove"
                    @click="removeImage(index, 'tablet10')"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </div>
              </b-col>
            </draggable>
            <!-- <b-row>
              <b-col
                v-for="(item, index) in tablet10"
                :key="index"
                cols="2"
              >
                <div>
                  <b-img
                    fluid-grow
                    :src="item"
                    rounded
                    class="mt-1 preview-imgs"
                    :alt="`tablet10${index}`"
                  />
                  <b-button
                    variant="danger"
                    class="btn-remove"
                    @click="removeImage(index, 'tablet10')"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </div>
              </b-col>
            </b-row> -->
          </div>
          <div class="mt-2 text-left">
            {{ tablet10.length }}/8
          </div>
        </b-card>
        <div>
          อัปโหลดภาพหน้าจอแท็บเล็ตขนาด 10 นิ้วได้สูงสุด 8 ภาพ
          ภาพหน้าจอต้องอยู่ในรูปแบบ PNG หรือ JPEG แต่ละภาพมีขนาดไม่เกิน 8 MB
          มีสัดส่วนภาพ 16:9 หรือ 9:16 โดยที่แต่ละด้านมีความละเอียดอยู่ระหว่าง
          1,080 ถึง 7,680 พิกเซล
        </div>
      </b-col>
    </b-row>
    <b-button
      size="sm"
      variant="primary"
      @click="saveData"
    >
      <span class="mr-25 align-middle"> {{ $t('Save') }}</span>
      <feather-icon
        icon="SaveIcon"
        size="12"
      />
    </b-button>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardGroup,
  BFormFile,
  BCardText,
  BImg,
  BOverlay,
  BButton,
} from 'bootstrap-vue'
import store from '@/store'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import storeModule from '@/store/services/eCommerce/graphicsStore'
import draggable from 'vuedraggable'

const STORE_MODULE_NAME = 'graphicsStore'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    BRow,
    BCol,
    BCard,
    BCardGroup,
    BFormFile,
    BCardText,
    BImg,
    BOverlay,
    BButton,
    draggable,
  },
  data() {
    return {
      featured_graphicIsDragging: false,
      phone_screenshotIsDragging: false,
      tablet7IsDragging: false,
      tablet10IsDragging: false,
      featured_graphic: [],
      phone_screenshot: [],
      tablet7: [],
      tablet10: [],
    }
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)

    this.show = true
    store
      .dispatch(`${STORE_MODULE_NAME}/get`)
      // eslint-disable-next-line no-unused-vars
      .then(result => {
        this.show = false
        const dataRes = result.data.data.android
        this.featured_graphic = dataRes.featuredGraphic
        this.phone_screenshot = dataRes.mobile
        this.tablet7 = dataRes.tablet.size7
        this.tablet10 = dataRes.tablet.size10
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        // eslint-disable-next-line no-unused-vars
      })
      .catch(error => {
        this.show = false
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
      })
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
  },
  setup() {
    return { STORE_MODULE_NAME }
  },
  methods: {
    saveData() {
      const obj = {
        os: 'android',
        featuredGraphic: this.featured_graphic,
        mobile: this.phone_screenshot,
        tablet: {
          size7: this.tablet7,
          size10: this.tablet10,
        },
      }
      store
        .dispatch(`${STORE_MODULE_NAME}/saveData`, obj)
        .then(result => {
          const dataRes = result.data.data.android
          this.featured_graphic = dataRes.featuredGraphic
          this.phone_screenshot = dataRes.mobile
          this.tablet7 = dataRes.tablet.size7
          this.tablet10 = dataRes.tablet.size10
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Success'),
              icon: 'CheckIcon',
              variant: 'success',
              text: this.$t('Success'),
            },
          })
          // this.showToast('success', 'bottom-right')
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'bottom-right')
        })
    },
    removeImage(index, type) {
      if (type === 'featured_graphic') {
        this.featured_graphic.splice(index, 1)
      } else if (type === 'phone_screenshot') {
        this.phone_screenshot.splice(index, 1)
      } else if (type === 'tablet7') {
        this.tablet7.splice(index, 1)
      } else if (type === 'tablet10') {
        this.tablet10.splice(index, 1)
      }
    },
    onChange(input, type) {
      // eslint-disable-next-line prefer-destructuring
      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader()
        reader.onload = e => {
          const image = new Image()
          image.src = e.target.result

          image.onload = () => {
            const { width, height } = image
            if (type === 'featured_graphic') {
              if (input.target.files[0].size > 15000000) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: `${this.$t('Allowed')} JPEG ${this.$t(
                      'or',
                    )} PNG. ${this.$t('Max size of')} 15 MB`,
                  },
                })
                return
              }
              if (width === 1024 && height === 500) {
                if (this.featured_graphic.length === 0) {
                  this.featured_graphic.push(e.target.result)
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: 'Resolution 1025 x 500 pixel',
                  },
                })
              }
            } else if (type === 'phone_screenshot') {
              if (input.target.files[0].size > 8000000) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: `${this.$t('Allowed')} JPEG ${this.$t(
                      'or',
                    )} PNG. ${this.$t('Max size of')} 8 MB`,
                  },
                })
                return
              }
              if (
                width >= 320
                && width <= 3840
                && height >= 320
                && height <= 3840
              ) {
                if (this.phone_screenshot.length <= 8) {
                  this.phone_screenshot.push(e.target.result)
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: 'Resolution w(320-3840) x h(320-3840) pixel',
                  },
                })
              }
            } else if (type === 'tablet7') {
              if (input.target.files[0].size > 8000000) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: `${this.$t('Allowed')} JPEG ${this.$t(
                      'or',
                    )} PNG. ${this.$t('Max size of')} 8 MB`,
                  },
                })
                return
              }
              if (
                width >= 320
                && width <= 3840
                && height >= 320
                && height <= 3840
              ) {
                if (this.tablet7.length <= 8) {
                  this.tablet7.push(e.target.result)
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: 'Resolution w(320-3840) x h(320-3840) pixel',
                  },
                })
              }
            } else if (type === 'tablet10') {
              if (input.target.files[0].size > 8000000) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: `${this.$t('Allowed')} JPEG ${this.$t(
                      'or',
                    )} PNG. ${this.$t('Max size of')} 8 MB`,
                  },
                })
                return
              }
              if (
                width >= 1080
                && width <= 7680
                && height >= 1080
                && height <= 7680
              ) {
                if (this.tablet10.length <= 8) {
                  this.tablet10.push(e.target.result)
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: 'Resolution w(1080-7680) x h(1080-7680) pixel',
                  },
                })
              }
            }
          }
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    dragover(e, type) {
      e.preventDefault()
      if (type === 'featured_graphic') {
        this.featured_graphicIsDragging = true
      } else if (type === 'phone_screenshot') {
        this.phone_screenshotIsDragging = true
      } else if (type === 'tablet7') {
        this.tablet7IsDragging = true
      } else if (type === 'tablet10') {
        this.tablet10IsDragging = true
      }
    },
    dragleave(type) {
      if (type === 'featured_graphic') {
        this.featured_graphicIsDragging = false
      } else if (type === 'phone_screenshot') {
        this.phone_screenshotIsDragging = false
      } else if (type === 'tablet7') {
        this.tablet7IsDragging = false
      } else if (type === 'tablet10') {
        this.tablet10IsDragging = false
      }
    },
    drop(e, type) {
      e.preventDefault()
      console.log(e)
      console.log(type)
      this.onChange({ target: { files: e.dataTransfer.files } }, type)
      if (type === 'featured_graphic') {
        this.featured_graphicIsDragging = false
      } else if (type === 'phone_screenshot') {
        this.phone_screenshotIsDragging = false
      } else if (type === 'tablet7') {
        this.tablet7IsDragging = false
      } else if (type === 'tablet10') {
        this.tablet10IsDragging = false
      }
    },
  },
}
</script>
