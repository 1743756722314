<template>
  <div
    v-if="$ability.can('read', this.$route.meta.resource)"
    class="p-2"
  >
    <b-card>
      <b-table-simple>
        <b-thead head-variant="primary">
          <b-tr>
            <b-td style="width: 20%;">
              {{ $t('List') }}
            </b-td>
            <b-td>{{ $t('Setting') }}</b-td>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr>
            <b-td>{{ $t('Brief description') }} : </b-td>
            <b-td>
              <b-form-input
                id="input-lg-brief-description"
                v-model="briefDescription"
                :placeholder="$t('Brief description')"
              />
            </b-td>
          </b-tr>
          <b-tr>
            <b-td>{{ $t('Full description') }} : </b-td>
            <b-td>
              <b-form-textarea
                id="input-lg-full-description"
                v-model="fullDescription"
                :placeholder="$t('Full description')"
              />
            </b-td>
          </b-tr>
          <b-tr>
            <b-td><b-button
              size="sm"
              variant="primary"
              @click="saveDescription()"
            >
              <feather-icon
                icon="SaveIcon"
                size="12"
              />
              <span class="align-middle"> {{ $t('Save') }}</span>
            </b-button></b-td>
            <b-td />
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>

    <b-tabs
      vertical
      card
    >
      <b-tab
        title="Android"
      >
        <Android />
      </b-tab>
      <b-tab title="iOS">
        <iOS />
      </b-tab>
    </b-tabs>
  </div>
</template>
<style>
.preview-img{
    /* width: 140px !important; */
    height: 500px;
    object-fit: cover;
}

.preview-imgs {
    width: 140px !important;
    height: 200px;
    object-fit: cover;
}
.btn-remove {
  position: absolute;
  top: 10%;
  right: 0px;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: red;
  color: white;
  font-size: 12px;
  padding: 8px 8px;
  border: none;
  cursor: pointer;
  border-radius: 15px;
  text-align: center;
}
</style>

<script>
import {
  BTabs, BTab,
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  BTd,
  BFormTags,
  BListGroupItem,
  BInputGroup,
  BInputGroupAppend,
  BButton,
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormTextarea,

} from 'bootstrap-vue'
import store from '@/store'
import storeModule from '@/store/services/eCommerce/graphicsStore'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import Android from './components/Android.vue'
import iOS from './components/iOS.vue'

const STORE_MODULE_NAME = 'graphicsStore'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    BTabs,
    BTab,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTd,
    BButton,
    BCard,
    BFormInput,
    BFormTextarea,
    Android,
    iOS,
  },
  data() {
    return {
      isDragging: false,
      briefDescription: '',
      fullDescription: '',
      files: [],
    }
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)

    this.show = true
    store
      .dispatch(`${STORE_MODULE_NAME}/get`)
      // eslint-disable-next-line no-unused-vars
      .then(result => {
        this.show = false
        const { briefDescription, fullDescription } = result.data.data
        this.briefDescription = briefDescription
        this.fullDescription = fullDescription
      })
      .catch(error => {
        this.show = false
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
      })
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
  },
  setup() {
    return { STORE_MODULE_NAME }
  },
  methods: {
    saveDescription() {
      const obj = {
        briefDescription: this.briefDescription,
        fullDescription: this.fullDescription,
      }
      store
        .dispatch(`${STORE_MODULE_NAME}/saveDescription`, obj)
        .then(() => {
          // this.showToast('success', 'bottom-right')
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'bottom-right')
        })
    },
    onChange() {
      this.files = [...this.$refs.file.files]
    },
    dragover(e) {
      e.preventDefault()
      this.isDragging = true
    },
    dragleave() {
      this.isDragging = false
    },
    drop(e) {
      e.preventDefault()
      this.$refs.file.files = e.dataTransfer.files
      this.onChange()
      this.isDragging = false
    },
  },
}
</script>
